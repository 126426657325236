import { TCheckkNmUpdateMaiwacsIntegSys, TcheckNmMaiwacsIntegSys } from './../../services/report/QualityReportTypes';
import { MaiwacsIntegSys } from './../maiwacs-integ-sys/maiwacsIntegSys';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IAlarm, IAlarmFilter, IAlarmSnapshot } from '..';
import {
  AlarmApi,
  QualityReportApi,
  TDeletetMaiwacsIntegSys,
  TGetAlarmsResult,
  TGetMaiwacsIntegSys,
  TGetMaiwacsIntegSysList,
  TPostMaiwacsIntegSys,
  TUpdateMaiwacsIntegSys,
} from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import {
  createMaiwacsIntegSys,
  IMaiwacsIntegSys,
  IMaiwacsIntegSysSnapshot,
} from '../maiwacs-integ-sys/maiwacsIntegSys';

export const MaiwacsIntegSysStore = types
  .model('MaiwacsIntegSysStore')
  .props({
    maiwacsIntegSysList: types.optional(types.array(createMaiwacsIntegSys()), []),
    selectedMaiwacsIntegSys: types.optional(createMaiwacsIntegSys()),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setMaiwacsIntegSysList: (maiwacsIntegSysList: IMaiwacsIntegSysSnapshot[]) => {
      self.maiwacsIntegSysList.replace(maiwacsIntegSysList as IMaiwacsIntegSys[]);
    },
    setSelectedMaiwacsIntegSys: (maiwacsIntegSys: IMaiwacsIntegSysSnapshot) => {
      self.selectedMaiwacsIntegSys = maiwacsIntegSys as IMaiwacsIntegSys;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00036
     * 알람 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=693327378
     * @param alarmFilter
     */
    gets: async () => {
      try {
        const qualityReport: QualityReportApi = new QualityReportApi(self.environment.api);
        const result: TGetMaiwacsIntegSysList = await qualityReport.getMaiwacsIntegSysList();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.maiwacsIntegSysList) {
            result.maiwacsIntegSysList.forEach((item: IMaiwacsIntegSysSnapshot) => {
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
              if (item.updDt) {
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              }
            });
            self.setMaiwacsIntegSysList(result.maiwacsIntegSysList);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    get: async (pltfrmSid: number) => {
      try {
        const qualityReport: QualityReportApi = new QualityReportApi(self.environment.api);
        const result: TGetMaiwacsIntegSys = await qualityReport.getMaiwacsIntegSys(pltfrmSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.maiwacsIntegSys) {
            self.setSelectedMaiwacsIntegSys(result.maiwacsIntegSys);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    post: async (maiwacsIntegSys: IMaiwacsIntegSysSnapshot) => {
      try {
        const qualityReport: QualityReportApi = new QualityReportApi(self.environment.api);
        const result: TPostMaiwacsIntegSys = await qualityReport.postMaiwacsIntegSys(
          maiwacsIntegSys,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.maiwacsIntegSys) {
            if (result.maiwacsIntegSys.regDt) {
              result.maiwacsIntegSys.regDt = timestampToDate(
                Number.parseInt(result.maiwacsIntegSys.regDt),
              );
            }
            if (result.maiwacsIntegSys.updDt) {
              result.maiwacsIntegSys.updDt = timestampToDate(
                Number.parseInt(result.maiwacsIntegSys.updDt),
              );
            }
            self.setSelectedMaiwacsIntegSys(result.maiwacsIntegSys);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    delete: async (pltfrmSid: number) => {
      try {
        const qualityReport: QualityReportApi = new QualityReportApi(self.environment.api);
        const result: TDeletetMaiwacsIntegSys = await qualityReport.deleteMaiwacsIntegSys(
          pltfrmSid,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    put: async (maiwacsIntegSys: IMaiwacsIntegSysSnapshot) => {
      try {
        const qualityReport: QualityReportApi = new QualityReportApi(self.environment.api);
        const result: TUpdateMaiwacsIntegSys = await qualityReport.updateMaiwacsIntegSys(
          maiwacsIntegSys,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.maiwacsIntegSys) {
            if (result.maiwacsIntegSys.regDt) {
              result.maiwacsIntegSys.regDt = timestampToDate(
                Number.parseInt(result.maiwacsIntegSys.regDt),
              );
            }
            if (result.maiwacsIntegSys.updDt) {
              result.maiwacsIntegSys.updDt = timestampToDate(
                Number.parseInt(result.maiwacsIntegSys.updDt),
              );
            }
            self.setSelectedMaiwacsIntegSys(result.maiwacsIntegSys);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    checkNm: async (maiwacsIntegSys: IMaiwacsIntegSysSnapshot) => {
        try {
          const qualityReport: QualityReportApi = new QualityReportApi(self.environment.api);
          const result: TcheckNmMaiwacsIntegSys = await qualityReport.checkNmMaiwacsIntegSys(
            maiwacsIntegSys,
          );
  
          if (self.rootStore.responseStore.getResponseResult(result)) {
           
          }
        } catch (e) {
          self.rootStore.responseStore.errorProcessing(e);
        }
      },
    
      checkNmUpdate: async (maiwacsIntegSys: IMaiwacsIntegSysSnapshot) => {
        try {
          const qualityReport: QualityReportApi = new QualityReportApi(self.environment.api);
          const result: TCheckkNmUpdateMaiwacsIntegSys = await qualityReport.checkNmUpdateMaiwacsIntegSys(
            maiwacsIntegSys,
          );
  
          if (self.rootStore.responseStore.getResponseResult(result)) {
           
          }
        } catch (e) {
          self.rootStore.responseStore.errorProcessing(e);
        }
      },
  }));

type TMaiwacsIntegSysStore = Instance<typeof MaiwacsIntegSysStore>;
type TMaiwacsIntegSysStoreSnapshot = SnapshotOut<typeof MaiwacsIntegSysStore>;

export interface IMaiwacsIntegSysStore extends TMaiwacsIntegSysStore {}
export type TMaiwacsIntegSysStoreKeys = keyof TMaiwacsIntegSysStoreSnapshot & string;
export interface IMaiwacsIntegSysStoreSnapshot extends TMaiwacsIntegSysStoreSnapshot {}
export const createMaiwacsIntegSysStore = () =>
  types.optional(MaiwacsIntegSysStore, {} as TMaiwacsIntegSysStore);
