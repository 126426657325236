import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

export const MaiwacsIntegSys = types
  .model('MaiwacsIntegSys')
  .props({
    pltfrmSid: types.optional(types.number, 0),
    pltfrmNm: types.optional(types.string, ''),
    dscrpt: types.optional(types.maybeNull(types.string), ''),
    useYn: types.optional(types.boolean, false),
    regDt: types.optional(types.maybeNull(types.string), ''),
    updDt: types.optional(types.maybeNull(types.string), ''),
    regUser: types.maybeNull(createSimpleUser()),

    updUser: types.maybeNull(createSimpleUser()),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMaiwacsIntegSys;
      (Object.keys(newProps) as TMaiwacsIntegSysKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TMaiwacsIntegSys = Instance<typeof MaiwacsIntegSys>;
type TMaiwacsIntegSysSnapshot = SnapshotOut<typeof MaiwacsIntegSys>;

export interface IMaiwacsIntegSys extends TMaiwacsIntegSys {}
export type TMaiwacsIntegSysKeys = keyof TMaiwacsIntegSysSnapshot & string;
export interface IMaiwacsIntegSysSnapshot extends TMaiwacsIntegSysSnapshot {}
export const createMaiwacsIntegSys = () => types.optional(MaiwacsIntegSys, {} as TMaiwacsIntegSys);
