import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CC } from '../../commonCodes/commonCodes';
import { Channel, createChannel, IChannel, IChannelSnapshot } from '../../models/channel/Channel';
import {
  TCheckIdResult,
  TDeleteChannelResult,
  TGetChannelResult,
  TGetChannelsResult,
  TLineupResult,
  TPostChannelResult,
  TUpdateChannelResult,
} from '../../services/channel/ChannelTypes';
import { timestampToDate } from '../../utils/dateExchanger';
import { IAco, IAcoSnapshot } from '../aco/Aco';
import { withEnvironment } from '../extensions/with-environment';
import { IHdMux } from '../hd-mux/HdMux';
import { IInputSw } from '../input-sw/InputSw';
import { ModelCode } from '../model-code/ModelCode';
import { IOutputSw } from '../output-sw/OutputSw';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { ISdMux } from '../sd-mux/SdMux';
import { ISdSwSnapshot } from '../sd-sw/SdSw';
import { ITrcodSnapshot } from '../trcod/Trcod';
import { ChannelApi } from './../../services/channel/Channel';
import { withRootStore } from './../extensions/with-root-store';
import {
  createSocketMessage,
  ISocketMessage,
  ISocketMessageSnapshot,
} from './../socket-message/SocketMessage';

let webSocket: WebSocket;
export const ChannelStore = types
  .model('ChannelStore')
  .props({
    channels: types.optional(types.array(Channel), []),
    totalChannels: types.optional(types.array(Channel), []),
    selectedChannel: types.maybeNull(createChannel()),
    selectedCopyChannel: types.maybeNull(createChannel()),
    deleteChannelList: types.optional(types.array(types.number), []),
    pagination: createPagination(),
    paginationForCopyModal: createPagination(),
    socketKey: types.optional(types.maybeNull(types.string), null),
    socketMessage: types.maybeNull(createSocketMessage()),
    socketDetailMessage: types.optional(types.string, ''),
    useLoaing: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setSelectedChannel: (channel: IChannelSnapshot) => {
      self.selectedChannel = channel as IChannel;
    },
    setSelectedCopyChannel: (channel: IChannelSnapshot) => {
      self.selectedCopyChannel = channel as IChannel;
    },
    setDeleteChannelList: (deleteChannelSvcId: number[]) => {
      self.deleteChannelList.replace(deleteChannelSvcId);
    },
    appendDeleteChannelList: (deleteChannelSvcId: number) => {
      self.deleteChannelList.push(deleteChannelSvcId);
    },
    setChannels: (channelList: IChannelSnapshot[]) => {
      if (!channelList) {
        return;
      }
      self.channels.replace(channelList as IChannel[]);
    },
    setTotalChannels: (channelList: IChannelSnapshot[]) => {
      if (!channelList) {
        return;
      }
      self.totalChannels.replace(channelList as IChannel[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSocketKey: (socketKey: string) => {
      self.socketKey = socketKey;
    },
    setSocketMessage: (socketMessage: ISocketMessageSnapshot) => {
      self.socketMessage = socketMessage as ISocketMessage;
    },
    messageConvertingForUI: () => {
      let result: string = ``;
      let size: number = NaN;
      if (self.socketMessage?.messages) {
        size = self.socketMessage?.messages?.length;
      }
      self.socketMessage?.messages?.forEach((item: string, index: number) => {
        result += item;
        if (index !== size - 1) {
          result += '\r\n';
        }
      });
      self.socketDetailMessage = result;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00143
     * 채널 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=763085185
     */
    gets: async (channelFilter?: TChannelFilter) => {
      self.setChannels([]);
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        let result: TGetChannelsResult;
        if (channelFilter) {
          result = await channelApi.gets(self.pagination, channelFilter);
        } else {
          result = await channelApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          self.pagination.page === result.pagination.page
        ) {
          if (!result.channels) {
            return;
          }

          if (result.channels.length > 0) {
            result.channels.forEach((item: IChannelSnapshot) => {
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
              if (item.updDt) {
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              }

              if (item.rglsComotDate) {
                item.rglsComotDate = timestampToDate(Number.parseInt(item.rglsComotDate));
              }

              let araList: any = [];
              let acoList: any = [];

              if (item.acoList) {
                item.acoList.forEach((aco: IAcoSnapshot) => {
                  if (aco.srcInType.code === CC.SORCE_IN_TYPE.Kt) {
                  } else if (aco.srcInType.code === CC.SORCE_IN_TYPE.ARA) {
                  }
                });
              }
            });
          }

          self.setChannels(result.channels);
          if (result.pagination) {
            self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getTotal: async () => {
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        let result: TGetChannelsResult;

        self.paginationForCopyModal.size = 10000

        result = await channelApi.gets(self.paginationForCopyModal);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.channels) {
            if (result.channels.length > 0) {
              result.channels.forEach((item: IChannelSnapshot) => {
                if (item.regDt) {
                  item.regDt = timestampToDate(Number.parseInt(item.regDt));
                }
                if (item.updDt) {
                  item.updDt = timestampToDate(Number.parseInt(item.updDt));
                }

                if (item.rglsComotDate) {
                  item.rglsComotDate = timestampToDate(Number.parseInt(item.rglsComotDate));
                }

                let araList: any = [];
                let acoList: any = [];

                if (item.acoList) {
                  item.acoList.forEach((aco: IAcoSnapshot) => {
                    if (aco.srcInType.code === CC.SORCE_IN_TYPE.Kt) {
                    } else if (aco.srcInType.code === CC.SORCE_IN_TYPE.ARA) {
                    }
                  });
                }
              });
            }

            self.setTotalChannels(result.channels);
          }
        }

      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }

    },

    /**
     * INF_CAIMS_00144
     * 채널 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=728987105
     */
    get: async (chSvcId: number) => {
      self.selectedChannel = null;
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TGetChannelResult = await channelApi.get(chSvcId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (!result.channel) {
            return;
          }

          if (result.channel) {
            let resultChannel: IChannelSnapshot;
            resultChannel = changeChannelDetail(result.channel as IChannel);
            self.setSelectedChannel(resultChannel);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getDetail: async (chSvcId: number) => {
      // self.selectedChannel = null;
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TGetChannelResult = await channelApi.get(chSvcId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (!result.channel) {
            return;
          }

          if (result.channel) {
            let resultChannel: IChannelSnapshot;
            resultChannel = changeChannelDetail(result.channel as IChannel);
            self.setSelectedCopyChannel(resultChannel);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00146
     * 채널 정보를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1002654055
     */
    post: async (channel: IChannelSnapshot) => {
      self.selectedChannel = null;
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TPostChannelResult = await channelApi.post(channel);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.channel) {
            let resultChannel: IChannelSnapshot;

            resultChannel = changeChannelDetail(result.channel as IChannel);
            self.setSelectedChannel(resultChannel);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00147
     * 채널 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1875736034
     */
    put: async (channel: IChannelSnapshot) => {
      self.selectedChannel = null;
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TUpdateChannelResult = await channelApi.put(channel);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.channel) {
            let resultChannel: IChannelSnapshot;

            resultChannel = changeChannelDetail(result.channel as IChannel);
            self.setSelectedChannel(resultChannel);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00148
     * 채널 단건 삭제를 한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=967575460
     */
    delete: async (chSvcId: number, params: IChannelSnapshot) => {
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TDeleteChannelResult = await channelApi.delete(chSvcId, params);

        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00149
     * 채널 목록 선택 삭제를 한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=684082287
     */
    deletes: async (params: IChannelSnapshot) => {
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TDeleteChannelResult = await channelApi.deletes(self.deleteChannelList, params);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00145
     * 채널 선번장을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1698908185
     */
    lineup: async (file: File) => {
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TLineupResult = await channelApi.lineup(file);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.socketKey) {
            self.socketMessage?.setProps({ key: result.socketKey });
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    socketCommunication: async () => {
      const { REACT_APP_WS_PREFIX, REACT_APP_WS_URL, REACT_APP_API_CONTEXT_PATH } = process.env;
      const baseUrl = REACT_APP_WS_URL || self.rootStore.commonStore.windowLocationOrigin.split('//')[1];
      const ws: WebSocket = new WebSocket(
        `${REACT_APP_WS_PREFIX}${baseUrl}/${REACT_APP_API_CONTEXT_PATH}/ws/channel?key=${self.socketMessage?.key}`,
      );
      webSocket = ws;
      if (!ws) {
        return;
      }
      ws.onopen = (event: Event) => {
        // self.socketMessage?.resetObject();
        const msg = {
          type: 'START',
          key: self.socketMessage?.key,
        };
        ws.send(JSON.stringify(msg));
      };

      ws.onmessage = (event: MessageEvent) => {
        // self.socketMessage?.resetObject();
        const message = JSON.parse(event.data);
        let tempMessage: ISocketMessageSnapshot = {} as ISocketMessageSnapshot;

        if (message.type === 'COMMUNICATION') {
          const choice = message.communication.choice;
          tempMessage = {
            ...message.communication,
            ...{
              trueLabel: Object.keys(choice).find((key) => choice[key] === true),
              falseLabel: Object.keys(choice).find((key) => choice[key] === false),
            },
          };
        }

        if (message.type === 'PROGRESS') {
          tempMessage = { ...message.progress };
        }
        if (message.type === 'END') {
          tempMessage = { ...message.result };
        }

        const socketMessage: ISocketMessageSnapshot = {
          ...message,
          ...tempMessage,
        } as ISocketMessageSnapshot;

        self.setSocketMessage(socketMessage);

        // TODO : 소켓 메시지 개발시에만 사용.
        //console.log({ ...self.socketMessage });

        if (message.type === 'END') {
          const msg = {
            type: 'END',
            key: self.socketMessage?.key,
          };
          ws.send(JSON.stringify(msg));
        }

        ws.onclose = (event: CloseEvent) => {
          self.socketMessage?.resetObject();
        };
      };
    },
    communicationAnswer: (answer: boolean) => {
      const msg = {
        type: 'COMMUNICATION',
        communication: {
          answer: answer,
        },
        key: self.socketMessage?.key,
      };
      webSocket.send(JSON.stringify(msg));
    },
    endSocket: () => {
      const msg = {
        type: 'END',
        key: self.socketMessage?.key,
      };
      if (webSocket) webSocket.send(JSON.stringify(msg));
    },

    /**
     * **********************************************
     * START 채널 라인업 초기 데이터 업로드
     * **********************************************
     */
    socketInitCommunication: async () => {
      const { REACT_APP_WS_PREFIX, REACT_APP_WS_URL, REACT_APP_API_CONTEXT_PATH } = process.env;
      const baseUrl = REACT_APP_WS_URL || self.rootStore.commonStore.windowLocationOrigin.split('//')[1];
      const ws: WebSocket = new WebSocket(
        `${REACT_APP_WS_PREFIX}${baseUrl}/${REACT_APP_API_CONTEXT_PATH}/ws/init/channel?key=${self.socketMessage?.key}`,
      );
      webSocket = ws;
      if (!ws) {
        return;
      }
      ws.onopen = (event: Event) => {
        // self.socketMessage?.resetObject();
        const msg = {
          type: 'START',
          key: self.socketMessage?.key,
        };
        ws.send(JSON.stringify(msg));
      };

      ws.onmessage = (event: MessageEvent) => {
        // self.socketMessage?.resetObject();
        const message = JSON.parse(event.data);
        let tempMessage: ISocketMessageSnapshot = {} as ISocketMessageSnapshot;

        if (message.type === 'COMMUNICATION') {
          const choice = message.communication.choice;
          tempMessage = {
            ...message.communication,
            ...{
              trueLabel: Object.keys(choice).find((key) => choice[key] === true),
              falseLabel: Object.keys(choice).find((key) => choice[key] === false),
            },
          };
        }

        if (message.type === 'PROGRESS') {
          tempMessage = { ...message.progress };
        }
        if (message.type === 'END') {
          tempMessage = { ...message.result };
        }

        const socketMessage: ISocketMessageSnapshot = {
          ...message,
          ...tempMessage,
        } as ISocketMessageSnapshot;

        self.setSocketMessage(socketMessage);

        // TODO : 소켓 메시지 개발시에만 사용.
        console.log({ ...self.socketMessage });

        if (message.type === 'END') {
          const msg = {
            type: 'END',
            key: self.socketMessage?.key,
          };
          ws.send(JSON.stringify(msg));
        }

        ws.onclose = (event: CloseEvent) => {
          self.socketMessage?.resetObject();
        };
      };
    },
    /**
     * **********************************************
     * END 채널 라인업 초기 데이터 업로드
     * **********************************************
     */

    /**
     * INF_CAIMS_00234
     * 채널의 서비스 아이디를 중복 체크한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=650294706
     * @param chSvcId
     */
    checkChSvcId: async (chSvcId: number) => {
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TCheckIdResult = await channelApi.checkChSvcId(chSvcId);

        self.rootStore.responseStore.getResponseResultWithOutSnackBar(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00235
     * 채널의 채널번호를 중복체크한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1789731756
     */
    checkChNo: async (chNo: number) => {
      try {
        const channelApi: ChannelApi = new ChannelApi(self.environment.api);
        const result: TCheckIdResult = await channelApi.checkChNo(chNo);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 채널 라인업 다운로드
     */
    downloadLineup: async (isAll: boolean, dedicatedChYn: boolean) => {
      const channelApi: ChannelApi = new ChannelApi(self.environment.api);
      await channelApi.downloadLineup(isAll, dedicatedChYn);
    },

    /**
     * 채널 라인업 템플릿 다운로드
     */
    downloadTemplate: async () => {
      const channelApi: ChannelApi = new ChannelApi(self.environment.api);
      await channelApi.downloadTemplate();
    },
  }));

type TChannelStore = Instance<typeof ChannelStore>;
type TChannelStoreSnapshot = SnapshotOut<typeof ChannelStore>;

export interface IChannelStore extends TChannelStore { }
export type TChannelStoreKeys = keyof TChannelStoreSnapshot & string;
export interface IChannelStoreSnapshot extends TChannelStoreSnapshot { }

export interface IChannelFilter {
  chSvcId?: number | string;
  chNo?: number | string;
  chNm?: string;
  dedicatedChYn?: boolean;
}

export type TChannelFilter = IChannelFilter;

//changeChannelDetail 에서 사용되는 ANY 타입은
//파라미터 변경으로 인한 ANY 로 사용 중
//정확한 규격이 Fix 되면 인터페이스로 변환할 것
const changeChannelDetail = (channel: IChannel) => {
  if (channel.regDt) {
    channel.regDt = timestampToDate(Number.parseInt(channel.regDt));
  }
  if (channel.updDt) {
    channel.updDt = timestampToDate(Number.parseInt(channel.updDt));
  }

  if (channel.rglsComotDate) {
    channel.rglsComotDate = timestampToDate(Number.parseInt(channel.rglsComotDate));
  }

  if (channel.pp) {
    if (channel.pp.regDt) {
      channel.pp.regDt = timestampToDate(Number.parseInt(channel.pp.regDt));
    }

    if (channel.pp.updDt) {
      channel.pp.updDt = timestampToDate(Number.parseInt(channel.pp.updDt));
    }
  }

  if (!channel.encodList || channel.encodList.length === 0) {
    channel.encodList = [{ mainYn: true }] as any;
  }

  if (channel.inputSwList && channel.inputSwList.length > 0) {
    channel.inputSwList.forEach((item: IInputSw) => {
      if (item.inPortSid) {
        item.inPort = ModelCode.create({
          code: item.inPortSid,
          value: item.inPortNm,
          pcode: 0,
        });
      }

      if (item.outPortSid) {
        item.outPort = ModelCode.create({
          code: item.outPortSid,
          value: item.outPortNm,
          pcode: 0,
        });
      }

      if (item.outBPortSid) {
        item.outBPort = ModelCode.create({
          code: item.outBPortSid,
          value: item.outBPortNm,
          pcode: 0,
        });
      }

      if (item.emgOutPortSid) {
        item.emgOutPort = ModelCode.create({
          code: item.emgOutPortSid,
          value: item.emgOutPortNm,
          pcode: 0,
        });
      }
    });
  } else {
    channel.inputSwList = [{ mainYn: true }] as any;
  }

  if (channel.acoList && channel.acoList.length > 0) {
    let channelAraAcoList: any = [];
    let channelAcoList: any = [];

    channel.acoList.forEach((item: IAco) => {
      if (item.inPortSid) {
        item.inPort = ModelCode.create({
          code: item.inPortSid,
          value: item.inPortNm,
          pcode: 0,
        });
      }

      if (item.skyAraInPortSid) {
        item.skyAraInPort = ModelCode.create({
          code: item.skyAraInPortSid,
          value: item.skyAraInPortNm,
          pcode: 0,
        });
      }

      if (item.lgInPortSid) {
        item.lgInPort = ModelCode.create({
          code: item.lgInPortSid,
          value: item.lgInPortNm,
          pcode: 0,
        });
      }

      if (item.psiInPortSid) {
        item.psiInPort = ModelCode.create({
          code: item.psiInPortSid,
          value: item.psiInPortNm,
          pcode: 0,
        });
      }

      if (item.outPortSid) {
        item.outPort = ModelCode.create({
          code: item.outPortSid,
          value: item.outPortNm,
          pcode: 0,
        });
      }

      if (item.skyOutPortSid) {
        item.skyOutPort = ModelCode.create({
          code: item.skyOutPortSid,
          value: item.skyOutPortNm,
          pcode: 0,
        });
      }

      if (item.seeznOutPortSid) {
        item.seeznOutPort = ModelCode.create({
          code: item.seeznOutPortSid,
          value: item.seeznOutPortNm,
          pcode: 0,
        });
      }

      if (item.inBPortSid) {
        item.inBPort = ModelCode.create({
          code: item.inBPortSid,
          value: item.inBPortNm,
          pcode: 0,
        });
      }

      if (item.outBPortSid) {
        item.outBPort = ModelCode.create({
          code: item.outBPortSid,
          value: item.outBPortNm,
          pcode: 0,
        });
      }

      if (item.srcInType.code === CC.SORCE_IN_TYPE.Kt) {
        channelAcoList.push(item);
      } else if (item.srcInType.code === CC.SORCE_IN_TYPE.ARA) {
        channelAraAcoList.push(item);
      }
    });

    if (channelAcoList.length > 0) {
      channel.acoList = channelAcoList;
    } else {
      channel.acoList = [{ mainYn: true }] as any;
    }
    if (channelAraAcoList.length > 0) {
      channel.acoAraList = channelAraAcoList;
    } else {
      channel.acoAraList = [{ mainYn: true }] as any;
    }
  } else {
    channel.acoList = [{ mainYn: true }] as any;
    channel.acoAraList = [{ mainYn: true }] as any;
  }

  if (channel.hdMuxList && channel.hdMuxList.length > 0) {
    channel.hdMuxList.forEach((item: IHdMux) => {
      if (item.inPortSid) {
        item.inPort = ModelCode.create({
          code: item.inPortSid,
          value: item.inPortNm,
          pcode: 0,
        });
      }

      if (item.outPortSid) {
        item.outPort = ModelCode.create({
          code: item.outPortSid,
          value: item.outPortNm,
          pcode: 0,
        });
      }

      if (item.scsPortSid) {
        item.scsPort = ModelCode.create({
          code: item.scsPortSid,
          value: item.scsPortNm,
          pcode: 0,
        });
      }



      if (item.inBPortSid) {
        item.inBPort = ModelCode.create({
          code: item.inBPortSid,
          value: item.inBPortNm,
          pcode: 0,
        });
      }

      if (item.outBPortSid) {
        item.outBPort = ModelCode.create({
          code: item.outBPortSid,
          value: item.outBPortNm,
          pcode: 0,
        });
      }
      if (item.emgInPortSid) {
        item.emgInPort = ModelCode.create({
          code: item.emgInPortSid,
          value: item.emgInPortNm,
          pcode: 0,
        });
      }
    });
  } else {
    channel.hdMuxList = [{ mainYn: true }] as any;
  }

  if (channel.sdMuxList && channel.sdMuxList.length > 0) {
    channel.sdMuxList.forEach((item: ISdMux) => {
      if (item.inPortSid) {
        item.inPort = ModelCode.create({
          code: item.inPortSid,
          value: item.inPortNm,
          pcode: 0,
        });
      }

      if (item.outPortSid) {
        item.outPort = ModelCode.create({
          code: item.outPortSid,
          value: item.outPortNm,
          pcode: 0,
        });
      }

      if (item.scsPortSid) {
        item.scsPort = ModelCode.create({
          code: item.scsPortSid,
          value: item.scsPortNm,
          pcode: 0,
        });
      }



      if (item.inBPortSid) {
        item.inBPort = ModelCode.create({
          code: item.inBPortSid,
          value: item.inBPortNm,
          pcode: 0,
        });
      }

      if (item.outBPortSid) {
        item.outBPort = ModelCode.create({
          code: item.outBPortSid,
          value: item.outBPortNm,
          pcode: 0,
        });
      }
      if (item.emgInPortSid) {
        item.emgInPort = ModelCode.create({
          code: item.emgInPortSid,
          value: item.emgInPortNm,
          pcode: 0,
        });
      }
    });
  } else {
    channel.sdMuxList = [{ mainYn: true }] as any;
  }

  if (channel.outputSwList && channel.outputSwList.length > 0) {
    channel.outputSwList.forEach((item: IOutputSw) => {
      if (item.inPortSid) {
        item.inPort = ModelCode.create({
          code: item.inPortSid,
          value: item.inPortNm,
          pcode: 0,
        });
      }

      if (item.outPortSid) {
        item.outPort = ModelCode.create({
          code: item.outPortSid,
          value: item.outPortNm,
          pcode: 0,
        });
      }
    });
  } else {
    channel.outputSwList = [{ mainYn: true }] as any;
  }

  if (channel.trcodList && channel.trcodList.length > 0) {
    channel.trcodList.forEach((item: ITrcodSnapshot) => {
      if (item.inPortSid) {
        item.inPort = ModelCode.create({
          code: item.inPortSid,
          value: item.inPortNm,
          pcode: 0,
        });
      }

      if (item.outPortSid) {
        item.outPort = ModelCode.create({
          code: item.outPortSid,
          value: item.outPortNm,
          pcode: 0,
        });
      }



      if (item.inBPortSid) {
        item.inBPort = ModelCode.create({
          code: item.inBPortSid,
          value: item.inBPortNm,
          pcode: 0,
        });
      }

      if (item.outBPortSid) {
        item.outBPort = ModelCode.create({
          code: item.outBPortSid,
          value: item.outBPortNm,
          pcode: 0,
        });
      }
    });
  } else {
    channel.trcodList = [{ mainYn: true }] as any;
  }

  if (channel.sdSwList && channel.sdSwList.length > 0) {
    channel.sdSwList.forEach((item: ISdSwSnapshot) => {
      if (item.inPortSid) {
        item.inPort = ModelCode.create({
          code: item.inPortSid,
          value: item.inPortNm,
          pcode: 0,
        });
      }

      if (item.outPortSid) {
        item.outPort = ModelCode.create({
          code: item.outPortSid,
          value: item.outPortNm,
          pcode: 0,
        });
      }


      if (item.inBPortSid) {
        item.inBPort = ModelCode.create({
          code: item.inBPortSid,
          value: item.inBPortNm,
          pcode: 0,
        });
      }

      if (item.outBPortSid) {
        item.outBPort = ModelCode.create({
          code: item.outBPortSid,
          value: item.outBPortNm,
          pcode: 0,
        });
      }
    });
  } else {
    channel.sdSwList = [{ mainYn: true }] as any;
  }

  const changeChannel: IChannelSnapshot = channel;

  return changeChannel;
};
