import { createChannelHistoryContent } from './../channel-history-content/ChannelHistoryContent';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from './../simple-user/SimpleUser';
import { toJS } from 'mobx';

export const ChannelHistory = types
  .model('ChannelHistory')
  .props({
    chChgWrkUid: types.optional(types.string, ''),
    chWrkType: createModelCode(),
    chWrkSttus: createModelCode(),
    // wrkDt: types.optional(types.string, ''),
    wrkDt: types.optional(types.maybeNull(types.string),''),
    wrkrId: types.optional(types.string, ''),
    wrkrNm: types.optional(types.string, ''),
    wrkrDept: types.optional(types.string, ''),
    fileNm: types.optional(types.maybeNull(types.string), null),
    fileConts: types.optional(types.maybeNull(types.string), null),
    chSvcIdList: types.optional(types.array(types.number), []),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
    wrkMemo: types.optional(types.maybeNull(types.string), ''),

    chg: types.maybeNull(createChannelHistoryContent()),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChannelHistory;
      (Object.keys(newProps) as TChannelHistoryKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    resetChannelHistory: () => {
      self.chChgWrkUid = '';
      self.wrkDt = '';
      self.wrkrId = '';
      self.wrkrNm = '';
      self.wrkrDept = '';
      self.fileNm = '';
      self.regDt = '';
      self.updDt = '';
      self.wrkMemo = '';
    },
  }));

type TChannelHistory = Instance<typeof ChannelHistory>;
type TChannelHistorySnapshot = SnapshotOut<typeof ChannelHistory>;

export interface IChannelHistory extends TChannelHistory {}
export type TChannelHistoryKeys = keyof TChannelHistorySnapshot & string;
export interface IChannelHistorySnapshot extends TChannelHistorySnapshot {}
export const createChannelHistory = () =>
  types.optional(ChannelHistory, {
    chChgWrkUid: '',
    wrkDt: '',
    wrkrId: '',
    wrkrNm: '',
    wrkrDept: '',
    fileNm: '',
    regDt: '',
    updDt: '',
    wrkMemo: '',
  } as TChannelHistory);
