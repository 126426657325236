import {
  OneviewDashboard,
  OneviewDashboardStoreModel,
} from './../oneview-dashboard/oneviewDashboardStore';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { AuthStore, AuthStoreModel } from '../../models/auth-store/auth-store';
import { BankStore, BankStoreModel } from '../../models/bank-store/bank-store';
import { BusinessStore, BusinessStoreModel } from '../../models/business-store/business-store';
import { ChannelStore, IChannelStore } from '../../models/channel-store/ChannelStore';
import { ChartStore, ChartStoreModel } from '../../models/chart-store/chart-store';
import { LanguageStore, LanguageStoreModel } from '../../models/language-store/language-store';
import { UserStore, UserStoreModel } from '../../models/user-store/user-store';
import { IUsrgrpStore, UsrgrpStore } from '../../models/usrgrp-store/UsrgrpStore';
import { AlarmStore, IAlarmStore } from '../alarm-store/AlarmStore';
import { RealTimeAlarmStore, IRealTimeAlarmStore } from '../alarm-store/RealTimeAlarmStore';
import {
  ChannelHistoryStore,
  IChannelHistoryStore,
} from '../channel-history-store/ChannelHistoryStore';
import { CommonStore, ICommonStore } from '../common-store/CommonStore';
import { ConnectionStore, IConnectionStore } from '../connection-store/ConnectionStore';
import { EquipmentStore, IEquipmentStore } from '../equipment-store/EquipmentStore';
import { ErrorAlertStore, IErrorAlertStore } from '../error-alert-store/ErrorAlertStore';
import { EventTypeStore, IEventTypeStore } from '../event-type-store/EventTypeStore';
import { HistoryStore, IHistoryStore } from '../history-store/HistoryStore';
import { IIntrlkSysStore, IntrlkSysStore } from '../intrlk-sys-store/IntrlkSysStore';
import { IKdbStore, KdbStore } from '../kdb-store/KdbStore';
import { ILoadingStore, LoadingStore } from '../loading-store/LoadingStore';
import {
  IOneViewStatisticsStore,
  OneViewStatisticsStore,
} from '../onview-statistics-store/OneViewStatisticsStore';
import { IPairAlarmStore, PairAlarmStore } from '../pair-alarm-store/PairAlarmStore';
import { IPpCompanyStore, PpCompanyStore } from '../pp-company-store/PpCompanyStore';
import {
  IRatingStatisticsStore,
  RatingStatisticsStore,
} from '../rating-statistics-store/RatingStatisticsStore';
import { IResponseStore, ResponseStore } from '../response-store/ResponseStore';
import { IRoleStore, RoleStore } from '../role-store/RoleStore';
import { IRulesetMappgStore, RulesetMappgStore } from '../ruleset-mappg-store/RulesetMappgStore';
import { IRulesetStore, RulesetStore } from '../ruleset-store/RulesetStore';
import { ISnmpRulesetStore, SnmpRulesetStore } from '../snmp-ruleset-store/SnmpRulesetStore';
import { ISopStore, SopStore } from '../sop-store/SopStore';
import {
  IStbStatisticsStore,
  StbStatisticsStore,
} from '../stb-statistics-store/StbStatisticsStore';
import { IStbStore, StbStore } from '../stb-store/StbStore';
import { IStbSimulatorStore, StbSimulatorStore } from '../stb-simulator-store/StbSimulatorStore';
import { createTopologyStore, TopologyStore } from '../topology';
import { IVendorStore, VendorStore } from '../vendor-store/VendorStore';
import {
  IVocStatisticsStore,
  VocStatisticsStore,
} from '../voc-statistics-store/VocStatisticsStore';
import { IVocStore, VocStore } from '../voc-store/VocStore';
import { ChannelCodeStore, IChannelCodeStore } from './../channel-code-store/ChannelCodeStore';

import { EquipGroupStore, IEquipGroupStore } from '../equip-group-store/EquipGroupStore';
import { IPortStore, PortStore } from './../port-store/PortStore';
import { IUsrgrpMenuStore, UsrgrpMenuStore } from './../usrgrp-menu-store/UsrgrpMenuStore';
import { LinkPortStore, ILinkPortStore } from '../link-port-store/LinkPortStore';
import { IQuetoneStore, QuetoneStore } from '../quetone-store/QuetoneStore';
import { IQuetoneGraphStore, QuetoneGraphStore } from '../quetone-graph-store/QuetoneGraphStore';
import { ISmsEmailStore, SmsEmailStore } from '../sms-email-store/SmsEmailStore';
import {
  ChPerfQltyRawStore,
  IChPerfQltyRawStore,
} from '../ch-perf-qlty-raw-store/ChPerfQltyRawStore';
import { createReportStore, IReportStore, ReportStore } from '../report-store/ReportStore';
import { INotificationStore, NotificationStore } from '../notification-store/NotificationStore';
import {
  UserLoginHistoryStore,
  UserLoginHistoryStoreModel,
} from '../user-login-history-store/user-login-history-store';
import { ChannelDbsStore, IChannelDbsStore } from '../channel-dbs-store/ChannelDbsStore';
import { ISystemStatusDashboardStore, SystemStatusDashboardStore } from '../system-status-dashboard-store/SystemStatusDashboardStore';
import { IQualityReportStore, QualityReportStore } from '../quality-report/QualityReportStore';
import { IMaiwacsIntegSysStore, MaiwacsIntegSysStore } from '../maiwacs-integ-sys-store/MaiwacsIntegSysStore';

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
    commonStore: types.optional(CommonStore, {} as ICommonStore), 
    chartStore: types.optional(ChartStoreModel, {} as ChartStore),
    businessStore: types.optional(BusinessStoreModel, {} as BusinessStore),
    bankStore: types.optional(BankStoreModel, {} as BankStore),
    userStore: types.optional(UserStoreModel, {} as UserStore),
    userLoginHistoryStore: types.optional(UserLoginHistoryStoreModel, {} as UserLoginHistoryStore),
    authStore: types.optional(AuthStoreModel, {} as AuthStore),
    languageStore: types.optional(LanguageStoreModel, {} as LanguageStore),
    errorAlertStore: types.optional(ErrorAlertStore, {} as IErrorAlertStore),
    usrgrpStore: types.optional(UsrgrpStore, {} as IUsrgrpStore),
    channelStore: types.optional(ChannelStore, {} as IChannelStore),
    ppCompanyStore: types.optional(PpCompanyStore, {} as IPpCompanyStore),
    equipmentStore: types.optional(EquipmentStore, {} as IEquipmentStore),
    roleStore: types.optional(RoleStore, {} as IRoleStore),
    channelCodeStore: types.optional(ChannelCodeStore, {} as IChannelCodeStore),
    usrgrpMenuStore: types.optional(UsrgrpMenuStore, {} as IUsrgrpMenuStore),
    historyStore: types.optional(HistoryStore, {} as IHistoryStore),
    channelHistoryStore : types.optional(ChannelHistoryStore, {} as IChannelHistoryStore),
    portStore: types.optional(PortStore, {} as IPortStore),
    vendorStore: types.optional(VendorStore, {} as IVendorStore),
    intrlkSysStore: types.optional(IntrlkSysStore, {} as IIntrlkSysStore),
    snmpRulesetStore: types.optional(SnmpRulesetStore, {} as ISnmpRulesetStore),
    rulesetStore:types.optional(RulesetStore, {} as IRulesetStore),
    rulesetMappStore:types.optional(RulesetMappgStore, {} as IRulesetMappgStore),
    stbStore: types.optional(StbStore, {} as IStbStore),
    connectionStore: types.optional(ConnectionStore, {} as IConnectionStore),
    responseStore:types.optional(ResponseStore, {} as IResponseStore),
    pairAlarmStore:types.optional(PairAlarmStore, {} as IPairAlarmStore),
    eventTypeStore: types.optional(EventTypeStore, {} as IEventTypeStore),
    loadingStore: types.optional(LoadingStore, {} as ILoadingStore),
    topologyStore: types.optional(TopologyStore, () => createTopologyStore()),
    kdbStore: types.optional(KdbStore, {} as IKdbStore),
    sopStore: types.optional(SopStore, {} as ISopStore),
    stbStatisticsStore: types.optional(StbStatisticsStore, {} as IStbStatisticsStore),
    vocStatisticsStore: types.optional(VocStatisticsStore, {} as IVocStatisticsStore),
    vocStore: types.optional(VocStore, {} as IVocStore),
    ratingStatisticsStore: types.optional(RatingStatisticsStore, {} as IRatingStatisticsStore),
    oneviewStatisticsStore: types.optional(OneViewStatisticsStore, {} as IOneViewStatisticsStore),
    equipGroupStore: types.optional(EquipGroupStore, {} as IEquipGroupStore),
    alarmStore: types.optional(AlarmStore, {} as IAlarmStore),
    realtimeAlarmStore: types.optional(RealTimeAlarmStore, {} as IRealTimeAlarmStore),
    stbSimulatorStore: types.optional(StbSimulatorStore, {} as IStbSimulatorStore),
    linkPortStore: types.optional(LinkPortStore, {} as ILinkPortStore),
    quetoneStore: types.optional(QuetoneStore, {} as IQuetoneStore),
    quetoneGraphStore: types.optional(QuetoneGraphStore, {} as IQuetoneGraphStore),
    smsEmailStore: types.optional(SmsEmailStore, {} as ISmsEmailStore),
    // misTransMissionGraphStore: types.optional(MisTransMissionGraphStore, {} as IMisTransMissionGraphStore),
    ChPerfQltyRawStore: types.optional(ChPerfQltyRawStore, {} as IChPerfQltyRawStore),
    reportStore: types.optional(ReportStore, {} as IReportStore),
    qualityReportStore: types.optional(QualityReportStore, {} as IQualityReportStore),
    maiwacsIntegSysStore: types.optional(MaiwacsIntegSysStore, {} as IMaiwacsIntegSysStore),
    notificationStore: types.optional(NotificationStore,  {} as INotificationStore),
    oneviewDashboardStore: types.optional(OneviewDashboardStoreModel, {} as OneviewDashboard),
    channelDbsStore: types.optional(ChannelDbsStore, {} as IChannelDbsStore),
    systemStatusDashboardStore: types.optional(SystemStatusDashboardStore, {} as ISystemStatusDashboardStore)
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
