import { IMaiwacsIntegSysSnapshot, MaiwacsIntegSys } from './../../models/maiwacs-integ-sys/maiwacsIntegSys';
import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { IModelCode, IPaginationSnapshot, IQualityReport, IQualityReportFilter, IQualityReportSnapshot, IResponseSnapshot } from '../../models';
import { 
  TDeletetMaiwacsIntegSys,
  TGetMaiwacsIntegSys,
  TGetMaiwacsIntegSysList,
  TGetQualityReportsResult,
  TPostMaiwacsIntegSys,
  TUpdateMaiwacsIntegSys,
 } from "./QualityReportTypes";
import { dateToTimestamp, timestampToDate } from "../../utils/dateExchanger";

  /**
  * # QualityReport Api 서비스
  * 
  * 서비스의 설명을 작성하세요.
  * 
  * ## 사용방법
  * 
  * ```ts
  * const service = new QualityReportApi();
  * 
  * ```
  */
 export class QualityReportApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(
    pagination: IPaginationSnapshot,
    qualityReportFilter?: IQualityReportFilter,
  ): Promise<TGetQualityReportsResult> {
    try {
      const url = '/quality/report';
      let params: any = {
        size: pagination.size,
        page: pagination.page,
      };

      if(qualityReportFilter?.rprtCycl)
      {
        params.rprtCycl = qualityReportFilter.rprtCycl;
      }

      if(qualityReportFilter?.chkDtSt)
      {
        params.chkDtSt = dateToTimestamp(qualityReportFilter.chkDtSt);
      }
      if(qualityReportFilter?.chkDtEd)
      {
        params.chkDtEd = dateToTimestamp(qualityReportFilter.chkDtEd);
      }

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          let qualityReports: IQualityReportSnapshot[] = response.data.data;
          qualityReports.forEach((item: IQualityReportSnapshot) => {
            item.regDt = item.regDt ? timestampToDate(Number.parseInt(item.regDt)) : '';
            item.chkDt = item.chkDt ? timestampToDate(Number.parseInt(item.chkDt)) : '';
          });
          const pagination: IPaginationSnapshot = response.data.pagination;
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            qualityReports: qualityReports,
            pagination: pagination,
          };
        }
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getPdf(qualityReport: IQualityReportSnapshot): Promise<any> {
    try {
      const url: string = `/quality/report/pdf?rprtCycl=${qualityReport?.rprtCycl?.code}&chkDt=${dateToTimestamp(qualityReport.chkDt)}`;

      let res = {};
      const response: ApiResponse<any> = await this.api.downloadFileToURL(url, 'temp.pdf');

      if (response.ok) {
        res = {
          kind: 'ok',
          responseInfo: response,
        };
      } else {
        res = {
          kind: 'error',
          responseInfo: response,
        };
      }

      return res;
    } catch (e) {
      console.log(e);
      return { kind: 'bad-data' };
    }
  }

   async downloadExcel(qualityReport: IQualityReportSnapshot): Promise<any> {
     const url: string = `/quality/report/excel?rprtCycl=${
       qualityReport?.rprtCycl?.code
     }&chkDt=${dateToTimestamp(qualityReport.chkDt)}`;
     return this.api.download(url, 'temp.xlsx');
   }

   async getMaiwacsIntegSysList(): Promise<TGetMaiwacsIntegSysList> {
     try {
       const url = '/quality/report/maiwacs-system';

       const response: ApiResponse<any> = await this.api.apisauce.get(url);

       if (!response.ok) {
         const problem = getGeneralApiProblem(response);
         if (problem) return problem;
       }

       const responseIfno: IResponseSnapshot = response.data;

       return {
         kind: 'ok',
         maiwacsIntegSysList: response.data.data,
         responseInfo: responseIfno,
       };
     } catch (e) {
       return { kind: 'bad-data' };
     }
   }

   async getMaiwacsIntegSys(pltfrmSid: number): Promise<TGetMaiwacsIntegSys> {
    try {
      const url = "/quality/report/maiwacs-system/"+ pltfrmSid

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        maiwacsIntegSys : response.data.data,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

   async postMaiwacsIntegSys(maiwacsIntegSys: IMaiwacsIntegSysSnapshot): Promise<TPostMaiwacsIntegSys> {
     try {
       const url = '/quality/report/maiwacs-system';
       const sendData = {
         pltfrmNm: maiwacsIntegSys.pltfrmNm,
         useYn: maiwacsIntegSys.useYn
       };

       const response: ApiResponse<any> = await this.api.apisauce.post(url, sendData);

       if (!response.ok) {
         const problem = getGeneralApiProblem(response);
         if (problem) return problem;
       }

       const responseIfno: IResponseSnapshot = response.data;

       return {
         kind: 'ok',
         maiwacsIntegSys : response.data.data,
         responseInfo: responseIfno,
       };
     } catch (e) {
       return { kind: 'bad-data' };
     }
   }

   async updateMaiwacsIntegSys(maiwacsIntegSys: IMaiwacsIntegSysSnapshot): Promise<TUpdateMaiwacsIntegSys> {
    try {
      const url = `/quality/report/maiwacs-system/${maiwacsIntegSys.pltfrmSid}`;
      const sendData = {
        pltfrmNm: maiwacsIntegSys.pltfrmNm,
        useYn: maiwacsIntegSys.useYn
      };

      const response: ApiResponse<any> = await this.api.apisauce.put(url, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        maiwacsIntegSys : response.data.data,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteMaiwacsIntegSys(pltfrmSid: number): Promise<TDeletetMaiwacsIntegSys> {
    try {
      const url = "/quality/report/maiwacs-system/"+ pltfrmSid;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async checkNmMaiwacsIntegSys(maiwacsIntegSys: IMaiwacsIntegSysSnapshot): Promise<TCheckkNmMaiwacsIntegSys> {
    try {
      const url = '/quality/report/maiwacs-system/check';
      const sendData = {
        pltfrmNm: maiwacsIntegSys.pltfrmNm,
        
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(url, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async checkNmUpdateMaiwacsIntegSys(maiwacsIntegSys: IMaiwacsIntegSysSnapshot): Promise<TCheckkNmUpdateMaiwacsIntegSys> {
    try {
      const url = '/quality/report/maiwacs-system/check/update';
      const sendData = {
        pltfrmNm: maiwacsIntegSys.pltfrmNm,
        pltfrmSid: maiwacsIntegSys.pltfrmSid
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(url, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
 }